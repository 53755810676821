import React, { useState, useEffect } from "react";
import "./../src/Assets/css/bootstrap.min.css";
import './App.scss';
import "./icon.scss"
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import Commanservice, { domain } from "./CommanService/commanService";
import { loginModal, headerLoginModal, FooterLoginModal, DefaultBillingAddress, storeFavCount, countCart, storeEntityId, storeCurrency, sliderAlignment } from "./Redux/action";
import Header from './Components/HeaderFooter/Header/header';
import { RouteComponent } from './Routes/routes';
import { Routes, Route, useLocation } from "react-router-dom";
import Footer from './Components/HeaderFooter/Footer/footer';
import Notification from "./CommanUIComp/Notification/Notification";
import { isEmpty, RandomId } from "./CommanFunctions/commanFunctions";
import BreadcrumbModule from "./CommanUIComp/Breadcrumb/breadcrumb";
import PageUnderConstruction from "./CommanUIComp/PageUnderConstruction/pageUnderConstruction";

function App() {

  const selector = useSelector(state => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const isLogin = Object.keys(selector.loginData).length > 0;

  // Toast Msg
  const [toastShow, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isPageSiteOpen, setIsPageSiteOpen] = useState(false);

  // Stap by Stap API
  const [callAPI, setCallAPI] = useState(false);

  const isJewelDiy = location.pathname.includes("start-with-a-setting");
  const isDiamoDiy = location.pathname.includes("start-with-a-diamond");

  const getStoreData = useCallback(() => {
    const getEntityId = {
      a: "GetStoreData",
      store_domain: domain,
      SITDeveloper: "1",
    };
    Commanservice.postApi('/EmbeddedPageMaster', getEntityId).then((res) => {
      if (res.data.success === 1) {
        const data = res.data.data;
        if (Object.keys(data).length > 0) {
          favouriteCartCount(data);
          templateData(data)
          dispatch(storeEntityId(data));
          document.title = res.data.data.store_name;
          sessionStorage.setItem("storeData", JSON.stringify(data));
          dispatch(storeCurrency(data.store_currency));
        }
      } else {
        sessionStorage.setItem("storeData", false)
        setToastOpen(true);
        setIsSuccess(false);
        setToastMsg(res.data.message);
      }
    }).catch(() => { sessionStorage.setItem("storeData", false) })
  }, [dispatch])

  const favouriteCartCount = (data) => {
    const obj = {
      "a": "get_count",
      "store_id": data !== undefined ? data.mini_program_id : selector.storeEntityId.mini_program_id,
      "user_id": isLogin ? selector.loginData.member_id : RandomId,
    }
    Commanservice.postLaravelApi("/CartMaster", obj).then((res) => {
      if (res.data.success === 1) {
        if (Object.keys(res.data.data).length > 0) {
          dispatch(storeFavCount(res.data.data?.favourite_count));
          dispatch(countCart(res.data.data?.cart_count));
          setCallAPI(true);
          if (selector.DefaultBillingAddress.length === 0) {
            if (isLogin) {
              const Address = {
                a: "GetBilling",
                user_id: isLogin ? selector.loginData.member_id : RandomId,
                store_id: selector.storeEntityId.mini_program_id,
                status: "1",
                per_page: "0",
                number: "0"
              }
              Commanservice.postLaravelApi('/BillingDetails', Address).then((res) => {
                if (res.data.success == 1) {
                  var billingData = res.data.data
                  if (billingData.length > 0) {
                    for (let c = 0; c < billingData.length; c++) {
                      if (billingData[c].status === 1) {
                        dispatch(DefaultBillingAddress(billingData[c]))
                      }
                    }
                  }
                }
              })
            }
          }
        }
      } else {
        setIsSuccess(false);
        setToastOpen(true);
        dispatch(storeFavCount(0));
        dispatch(countCart(0));
        setToastMsg(res.data.message);
      }
    }).catch(() => {
      dispatch(storeFavCount(0));
      dispatch(countCart(0));
    })
  };

  const templateData = (data) => {
    var preview = '1';
    if ((window.location.href).includes('preview') == true) {
      preview = '1';
    } else {
      preview = '0';
    }
    const obj = {
      a: "PublishTemplate",
      transaction_id: data.transaction_b2c_id,
      host: domain,
      SITDeveloper: "1",
      preview: preview,
      secret_key: data.secret_key
    }
    Commanservice.postApi("/TemplateMaster", obj).then((res) => {
      if (res.data.success === 1) {
        setIsPageSiteOpen(false)
        const Dynamic_color = res.data.data
        const publish_json = isEmpty(Dynamic_color["publish_json"]) !== "" ? (Dynamic_color["publish_json"]) : "";
        const publish = isEmpty(JSON.parse(publish_json)) !== "" ? JSON.parse(publish_json) : {};
        if (Object.keys(publish).length > 0) {

          if (isEmpty(publish["header"]["slider"]) !== "") {
            dispatch(sliderAlignment(isEmpty(publish["header"]["slider"].alignment)))
          }
          if (isEmpty(publish["header"]) !== "") {
            if (isEmpty(publish["header"]["Top_header"]) !== "") {
              isEmpty(publish["header"]["Top_header"].Bg_color) !== "" && document.documentElement.style.setProperty('--theme-top-header-bg-color', publish["header"]["Top_header"].Bg_color);
              isEmpty(publish["header"]["Top_header"].Text_color) !== "" && document.documentElement.style.setProperty('--theme-top-header-text-color', publish["header"]["Top_header"].Text_color);
              isEmpty(publish["header"]["Top_header"].Text_hover) !== "" && document.documentElement.style.setProperty('--theme-top-header-hover-color', publish["header"]["Top_header"].Text_hover);
            }

            if (isEmpty(publish["header"]["second_header"]) !== "") {
              isEmpty(publish["header"]["second_header"].Title_color) !== "" && document.documentElement.style.setProperty('--theme-Second-header-title-color', publish["header"]["second_header"].Title_color);
              isEmpty(publish["header"]["second_header"].Sub_title_color) !== "" && document.documentElement.style.setProperty('--theme-Second-header-sub-title-color', publish["header"]["second_header"].Sub_title_color);
              isEmpty(publish["header"]["second_header"].Text_color) !== "" && document.documentElement.style.setProperty('--theme-Second-header-text-color', publish["header"]["second_header"].Text_color);
              isEmpty(publish["header"]["second_header"].Text_hover) !== "" && document.documentElement.style.setProperty('--theme-Second-header-hover-color', publish["header"]["second_header"].Text_hover);
              isEmpty(publish["header"]["second_header"].Bg_color) !== "" && document.documentElement.style.setProperty('--theme-Second-header-bg-color', publish["header"]["second_header"].Bg_color);
            }

            if (isEmpty(publish["header"]["slider"]) !== "") {
              isEmpty(publish["header"]["slider"].Title_color) !== "" && document.documentElement.style.setProperty('--theme-slider-title-color', publish["header"]["slider"].Title_color);

              isEmpty(publish["header"]["slider"].Sub_title_color) !== "" && document.documentElement.style.setProperty('--theme-slider-sub-title-color', publish["header"]["slider"].Sub_title_color);

              isEmpty(publish["header"]["slider"].Text_color) !== "" && document.documentElement.style.setProperty('--theme-slider-text-color', publish["header"]["slider"].Text_color);

              isEmpty(publish["header"]["slider"].Text_hover) !== "" && document.documentElement.style.setProperty('--theme-slider-hover-color', publish["header"]["slider"].Text_hover);
            }
          }

          if (isEmpty(publish["footer"]) !== "" && isEmpty(publish["footer"]["footer_data"]) !== "") {
            isEmpty(publish["footer"]["footer_data"].Title_color) !== "" && document.documentElement.style.setProperty('--theme-footer-title-color', publish["footer"]["footer_data"].Title_color);
            isEmpty(publish["footer"]["footer_data"].Sub_title_color) !== "" && document.documentElement.style.setProperty('--footer-sub-title-color', publish["footer"]["footer_data"].Sub_title_color);
            isEmpty(publish["footer"]["footer_data"].Text_color) !== "" && document.documentElement.style.setProperty('--theme-footer-text-color', publish["footer"]["footer_data"].Text_color);
            isEmpty(publish["footer"]["footer_data"].Text_hover) !== "" && document.documentElement.style.setProperty('--theme-footer-hover-color', publish["footer"]["footer_data"].Text_hover);
            isEmpty(publish["footer"]["footer_data"].Bg_color) !== "" && document.documentElement.style.setProperty('--theme-footer-bg-color', publish["footer"]["footer_data"].Bg_color);
          }

          if (isEmpty(publish["bodyContent"]) !== "" && isEmpty(publish["bodyContent"]["body_content"]) !== "") {
            isEmpty(publish["bodyContent"]["body_content"].Title_color) !== "" && document.documentElement.style.setProperty('--theme-body-title-color', publish["bodyContent"]["body_content"].Title_color);
            isEmpty(publish["bodyContent"]["body_content"].Sub_title_color) !== "" && document.documentElement.style.setProperty('--theme-body-sub-title-color', publish["bodyContent"]["body_content"].Sub_title_color);
            isEmpty(publish["bodyContent"]["body_content"].Text_color) !== "" && document.documentElement.style.setProperty('--theme-body-text-color', publish["bodyContent"]["body_content"].Text_color);
            isEmpty(publish["bodyContent"]["body_content"].Text_hover) !== "" && document.documentElement.style.setProperty('--theme-body-hover-color', publish["bodyContent"]["body_content"].Text_hover);
            isEmpty(publish["bodyContent"]["body_content"].Bg_color) !== "" && document.documentElement.style.setProperty('--theme-body-bg-color', publish["bodyContent"]["body_content"].Bg_color);
            isEmpty(publish["bodyContent"]["body_content"].sec_Bg_color) !== "" && document.documentElement.style.setProperty('--theme-body-sub-bg-color', publish["bodyContent"]["body_content"].sec_Bg_color);
            isEmpty(publish["bodyContent"]["body_content"].nav_tab_color) !== "" && document.documentElement.style.setProperty('--theme-body-nav-tab-color', publish["bodyContent"]["body_content"].nav_tab_color);
          }

          if (isEmpty(publish["button"]) !== "" && isEmpty(publish["button"]["button_data"]) !== "") {
            isEmpty(publish["button"]["button_data"].button_bg_color) !== "" && document.documentElement.style.setProperty('--theme-btn-primary-bg-color', publish["button"]["button_data"].button_bg_color);
            isEmpty(publish["button"]["button_data"].button_text_color) !== "" && document.documentElement.style.setProperty('--theme-btn-primary-text-color', publish["button"]["button_data"].button_text_color);
            isEmpty(publish["button"]["button_data"].button_text_hover) !== "" && document.documentElement.style.setProperty('--theme-btn-primary-hover-color', publish["button"]["button_data"].button_text_hover);
          }
          if (isEmpty(publish["button"]) !== "" && isEmpty(publish["button"]["button_data"]) !== "") {

            isEmpty(publish["button"]["button_data"].sec_button_bg_color) !== "" && document.documentElement.style.setProperty('--theme-btn-secondary-bg-color', publish["button"]["button_data"].sec_button_bg_color);


            isEmpty(publish["button"]["button_data"].sec_button_text_color) !== "" && document.documentElement.style.setProperty('--theme-btn-secondary-text-color', publish["button"]["button_data"].sec_button_text_color);

            isEmpty(publish["button"]["button_data"].sec_button_text_hover) !== "" && document.documentElement.style.setProperty('--theme-btn-secondary-hover-color', publish["button"]["button_data"].sec_button_text_hover);
          }
        }
      } else {
        setIsPageSiteOpen(true)
        setToastOpen(true)
        setIsSuccess(false)
        setToastMsg(res.data.message)
      }
    }).catch(() => {
      setIsPageSiteOpen(true)
    })
  }

  useEffect(() => {
    var storeData = JSON.parse(sessionStorage.getItem("storeData"));
    if (storeData !== null && storeData !== undefined && storeData !== false) {
      document.title = storeData.store_name;
      favouriteCartCount(storeData);
      templateData(storeData)
    } else {
      getStoreData();
    }
    dispatch(loginModal(false));
    dispatch(headerLoginModal(false));
    dispatch(FooterLoginModal(false));

    var count = 0;
    var count2 = 0;
    setInterval(() => {
      // Product Height
      var className2 = document.querySelector(".product-img-separate");
      if (className2 !== null && className2 !== undefined && className2 !== "") {
        var divElement2 = document.querySelector(".product-img-separate");
        if (divElement2.getBoundingClientRect() !== null && divElement2.getBoundingClientRect() !== undefined) {
          var elemRect2 = divElement2.getBoundingClientRect();
          var elemHeight2 = elemRect2.width;
          if (elemHeight2 !== 0) {
            var height2 = document.getElementsByTagName('figure');
            if (height2.length > count2) {
              height2[count2].setAttribute("style", `height:${elemHeight2 + "px"};`);
              count2++;
            } else {
              count2 = 0;
            }
          }
        }
      }

      // Skeleton Height
      var className = document.getElementsByClassName("Skeleton");
      if (className !== null && className !== undefined && className.length > 0) {
        var divElement = document.querySelector(".Skeleton");
        if (divElement.getBoundingClientRect() !== null && divElement.getBoundingClientRect() !== undefined) {
          var elemRect = divElement.getBoundingClientRect();
          var elemHeight = elemRect.width;
          var height = document.getElementsByClassName('.Skeleton');
          if (height.length > count) {
            height[count].setAttribute("style", `height:${elemHeight + "px"};`);
            count++;
          } else {
            count = 0;
          }
        }
      }

      // loader hidden
      var loader = document.getElementById("loader");
      var body = document.getElementById("body");
      var active = document.getElementsByClassName("navbar-toggler active");
      var lgactive = document.getElementsByClassName('product-detail_right');
      if (loader !== null || active.length > 0) {
        body.setAttribute("style", "overflow:hidden;");
        if (lgactive.length == 0) {
          window.scrollTo(0, 0);
        }
      } else {
        if (active.length === 0) {
          body.setAttribute("style", "overflow:visible;")
        }
      }
    }, 1);
  }, []);

  return (
    <>
      {!isPageSiteOpen ?
        <>
          {callAPI && <Header />}

          {isJewelDiy != true && isDiamoDiy != true ?
            < BreadcrumbModule /> : ''
          }

          <Routes>
            {RouteComponent.map((route, index) => {
              return <Route
                key={index}
                path={route.path}
                component={route.component}
                element={route.element}
              />
            }
            )}
          </Routes>
          <Footer />

        </>
        :
        <PageUnderConstruction />
      }
      <Notification toastMsg={toastMsg} toastShow={toastShow} isSuccess={isSuccess} Close={() => setToastOpen()} />
    </>
  );
}

export default App;
