export const countCart = (data) => {
  return {
    type: "countCart",
    countCart: data,
  };
};

export const loginModal = (value) => {
  return {
    type: "loginModal",
    loginModal: value,
  };
};

export const headerLoginModal = (value) => {
  return {
    type: "headerLoginModal",
    headerLoginModal: value,
  };
};

export const FooterLoginModal = (value) => {
  return {
    type: "FooterLoginModal",
    FooterLoginModal: value,
  };
};

export const loginData = (data) => {
  return {
    type: "loginData",
    loginData: data,
  };
};

export const storeForgotVar = (value) => {
  return {
    type: "storeForgotVar",
    value: value,
  };
};

export const storeFavCount = (storeFavCountVal) => {
  return {
    type: "storeFavCount",
    storeFavCount: storeFavCountVal,
  };
};

export const diamondPageChnages = (value) => {
  return {
    type: "diamondPageChnages",
    diamondPageChnages: value,
  };
};

export const diamondNumber = (value) => {
  return {
    type: "diamondNumber",
    diamondNumber: value,
  };
};

export const storeEntityId = (storeEntityId) => {
  return {
    type: "storeEntityId",
    storeEntityId: storeEntityId,
  };
};

export const HeaderLogoData = (HeaderLogoData) => {
  return {
    type: "HeaderLogoData",
    HeaderLogoData: HeaderLogoData,
  };
};

export const DefaultBillingAddress = (DefaultBillingAddress) => {
  return {
    type: "DefaultBillingAddress",
    DefaultBillingAddress: DefaultBillingAddress,
  };
};
export const activeDIYtabs = (activeDIYtabs) => {
  return {
    type: "activeDIYtabs",
    activeDIYtabs: activeDIYtabs,
  };
};


export const jeweleryDIYName = (jeweleryDIYName) => {
  return {
    type: "jeweleryDIYName",
    jeweleryDIYName: jeweleryDIYName,
  };
};

export const jeweleryDIYimage = (jeweleryDIYimage) => {
  return {
    type: "jeweleryDIYimage",
    jeweleryDIYimage: jeweleryDIYimage,
  };
};
export const diamondDIYName = (diamondDIYName) => {
  return {
    type: "diamondDIYName",
    diamondDIYName: diamondDIYName,
  };
};

export const diamondDIYimage = (diamondDIYimage) => {
  return {
    type: "diamondDIYimage",
    diamondDIYimage: diamondDIYimage,
  };
};

export const isJewelDIY = (isJewelDIY) => {
  return {
    type: "isJewelDIY",
    isJewelDIY: isJewelDIY,
  };
};

export const isDiamoDIY = (isDiamoDIY) => {
  return {
    type: "isDiamoDIY",
    isDiamoDIY: isDiamoDIY,
  };
};

export const storeCurrencyData = (storeCurrencyData) => {
  return {
    type: "storeCurrencyData",
    storeCurrencyData: storeCurrencyData,
  };
}

export const storeCurrency = (storeCurrency) => {
  return {
    type: "storeCurrency",
    storeCurrency: storeCurrency,
  };
}
export const logoDetail = (value) => {
  return {
    type: "logoDetail",
    logoDetail: value,
  }
}
export const sliderAlignment = (value) => {
  return {
    type: "sliderAlignment",
    sliderAlignment: value,
  }
}